import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import "./NoteCard.css";

const NoteCard = ({ note, heuristicLabel, isExpanded, onExpand, fullNote }) => {
  const truncatedNote = note.length > 150 ? `${note.slice(0, 150)}...` : note;

  return (
    <div
      className={`note-card ${isExpanded ? "expanded" : ""}`}
      onClick={onExpand}
    >
      <div className="note-header">
        <FontAwesomeIcon icon={faNoteSticky} />
        <span className="note-heuristic">{heuristicLabel}</span>
      </div>
      <p className="note-content">{truncatedNote}</p>
    </div>
  );
};

export default NoteCard;
