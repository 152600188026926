// JoinUsSection.js
import React from "react";

const JoinUsSection = () => (
  <section className="join-us">
    <h2>Know your customers users better.</h2>
    <div className="join-options">{/* <button>Sign Up Now</button> */}</div>
  </section>
);

export default JoinUsSection;
