import React, { useState } from "react";
import "./CoPilotAvatar.css";
import CoPilotChat from "./CoPilotChat";
import uselyLogo from "./images/usely-logo.svg";

const CoPilotAvatar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="co-pilot-avatar" onClick={toggleChat}>
        <img src={uselyLogo} alt="Usely" className="logo" />
      </div>
      {isOpen && <CoPilotChat onClose={toggleChat} />}
    </>
  );
};

export default CoPilotAvatar;
