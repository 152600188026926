import React from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotesFilter = ({
  searchTerm,
  onSearchChange,
  currentFilter,
  onFilterChange,
}) => {
  const heuristics = [
    { id: "all", label: "All Heuristics" },
    { id: "h1", label: "H1: Visibility of System Status" },
    { id: "h2", label: "H2: Match Between System and Real World" },
    { id: "h3", label: "H3: User Control and Freedom" },
    { id: "h4", label: "H4: Consistency and Standards" },
    { id: "h5", label: "H5: Error Prevention" },
    { id: "h6", label: "H6: Recognition Rather Than Recall" },
    { id: "h7", label: "H7: Flexibility and Efficiency" },
    { id: "h8", label: "H8: Aesthetic and Minimalist Design" },
    { id: "h9", label: "H9: Help Users with Errors" },
    { id: "h10", label: "H10: Help and Documentation" },
  ];

  return (
    <div className="notes-controls">
      <div className="search-container">
        <input
          type="text"
          className="notes-search"
          placeholder="Search notes..."
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        {searchTerm && (
          <button className="clear-search" onClick={() => onSearchChange("")}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        )}
      </div>
      <select
        value={currentFilter}
        onChange={(e) => onFilterChange(e.target.value)}
        className="notes-filter"
      >
        {heuristics.map((h) => (
          <option key={h.id} value={h.id}>
            {h.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default NotesFilter;
