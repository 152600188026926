import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getScoreColor } from "./utils/ScoreColor";
import "./TableView.css";

const TableView = ({
  pages,
  heuristics,
  heuristicData,
  heuristicDataPC,
  setSelectedDataPoint,
  setThirdPaneOpen,
  onDataPointClick,
  data,
}) => {
  const [selectedDataPoint, setDataPoint] = useState(null);
  const navigate = useNavigate();

  const handleTableCellClick = (page, heuristic, event) => {
    // Remove highlight from previously selected cell and row
    const previouslyHighlightedCell =
      document.querySelector(".highlighted-cell");
    if (previouslyHighlightedCell) {
      previouslyHighlightedCell.classList.remove("highlighted-cell");
    }

    const previouslyHighlightedRow = document.querySelector(".highlighted-row");
    if (previouslyHighlightedRow) {
      previouslyHighlightedRow.classList.remove("highlighted-row");
    }

    // Highlight the clicked cell and row
    event.target.classList.add("highlighted-cell");
    event.target.parentElement.classList.add("highlighted-row");

    const heuristicIndex = heuristics.indexOf(heuristic);
    const heuristicEntry = heuristicData[page.id]?.[heuristic];
    const value = parseFloat(heuristicEntry?.[0]);

    if (heuristicEntry && !isNaN(value)) {
      const notes = heuristicEntry[1] || "";
      const links = heuristicEntry[2] || [];

      const dataPoint = {
        label: heuristic,
        value,
        // Normalize notes and links structure
        notes: {
          [heuristicIndex]: notes,
        },
        links: {
          [heuristicIndex]: Array.isArray(links)
            ? links
            : [links].filter(Boolean),
        },
        pageLabel: page.title,
        color: getScoreColor(value),
        id: page.id,
        index: heuristicIndex,
      };
      handleSetDataPoint(dataPoint);
      onDataPointClick(dataPoint, heuristicDataPC);
    }
  };

  const handleProductAreaClick = (pageId) => {
    navigate(`/page/${pageId}`);
  };

  // Set the selected data point in both local and passed state
  const handleSetDataPoint = (dataPoint) => {
    setDataPoint(dataPoint);
    setSelectedDataPoint(dataPoint);
    setThirdPaneOpen(true);
  };
  return (
    <div className="heuristic-table-container">
      <table className="heuristic-table">
        <thead>
          <tr>
            <th>Product Area</th>
            {heuristics.map((heuristic) => (
              <th key={heuristic}>{heuristic}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pages.map((page) => (
            <tr key={page.id}>
              <td
                onClick={() => handleProductAreaClick(page.id)}
                style={{ cursor: "pointer" }}
              >
                {page.title}
              </td>
              {heuristics.map((heuristic) => (
                <td
                  key={`${page.id}-${heuristic}`}
                  style={{
                    backgroundColor: getScoreColor(
                      parseFloat(heuristicData[page.id]?.[heuristic]?.[0])
                    ),
                  }}
                  onClick={(event) =>
                    handleTableCellClick(page, heuristic, event)
                  }
                >
                  {typeof heuristicData[page.id]?.[heuristic]?.[0] === "number"
                    ? heuristicData[page.id][heuristic][0].toFixed(1)
                    : heuristicData[page.id]?.[heuristic]?.[0] || "-"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
