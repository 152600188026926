// PrivateRoute.js

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const PrivateRoute = ({ children, requiredRoles = [] }) => {
  const { currentUser, authIsReady } = useContext(AuthContext);

  if (!authIsReady) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  // Define user roles based on currentUser properties
  const userRoles = [];
  if (currentUser.isAdmin) userRoles.push("isAdmin");
  if (currentUser.isSuperAdmin) userRoles.push("isSuperAdmin");
  if (currentUser.isResearcher) userRoles.push("isResearcher");

  // console.log("Auth User:", currentUser);
  // console.log("Required Roles:", requiredRoles);
  // console.log("User Roles:", userRoles);

  // Check if user has at least one of the required roles
  const hasRequiredRole =
    requiredRoles.length === 0 ||
    requiredRoles.some((role) => userRoles.includes(role));

  if (!hasRequiredRole) {
    // Optionally, redirect to a "Not Authorized" page
    return <Navigate to="/home" replace />;
  }

  return children;
};

export default PrivateRoute;
