import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { heuristics } from "./utils/commonUtils";
import "./DataEntryForm.css";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  setDoc,
  getDoc,
  doc,
  deleteDoc,
  writeBatch,
  onSnapshot,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faChevronDown,
  faChevronUp,
  faPlus,
  faCog,
  faCalculator,
  faNoteSticky,
  faSpinner,
  faLink,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useLocation } from "react-router-dom";
import Lightbox from "./Lightbox";

const DataEntryForm = ({ onDataUpdate = () => {} }) => {
  const location = useLocation();
  const { pageId } = location.state || {};
  const [formData, setFormData] = useState({});
  const [linkInputs, setLinkInputs] = useState({});
  const [selectedRelease, setSelectedRelease] = useState("");
  const [selectedPage, setSelectedPage] = useState("");
  const [newRelease, setNewRelease] = useState("");
  const [pages, setPages] = useState([]);
  const [releasesList, setReleasesList] = useState([]);
  const [isReleaseFlyoutOpen, setIsReleaseFlyoutOpen] = useState(false);
  const [isPageFlyoutOpen, setIsPageFlyoutOpen] = useState(false);
  const [notes, setNotes] = useState({});
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [firestoreData, setFirestoreData] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [noteInputs, setNoteInputs] = useState({});
  const [pageSearchTerm, setPageSearchTerm] = useState("");
  const isMergingRef = useRef(false);
  const [isParentPage, setIsParentPage] = useState(false);
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  const [showCloneSelect, setShowCloneSelect] = useState(false);
  const [cloneSource, setCloneSource] = useState("");
  const [lightboxMessage, setLightboxMessage] = useState(
    "Uploading data... Breathe... You are doing great!"
  );

  const [uploadStatus, setUploadStatus] = useState("idle");

  const [cloneInProgress, setCloneInProgress] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const isSuperAdmin = currentUser?.superAdmin;
  // Add clone handling functions
  const handleCloneClick = useCallback(() => {
    console.log("Clone button clicked");
    setIsLightboxVisible((prev) => true);
    setShowCloneSelect((prev) => true);
    setLightboxMessage("Select release to clone data from:");
  }, []);

  const performDataUpdate = async () => {
    setUploadStatus("uploading");
    setLightboxMessage("Uploading data... Please wait.");
    setIsLightboxVisible((prev) => true);
    try {
      const batch = writeBatch(db);
      const differences = getDifferences(
        firestoreData,
        formData,
        {}, // Assuming we don't have oldNotes stored separately
        noteInputs, // Use the current note inputs here
        {}, // Assuming we don't have oldLinks stored separately
        linkInputs
      );

      Object.keys(differences).forEach((release) => {
        const releaseRef = doc(db, "releases", release);
        const updateData = {};

        Object.keys(differences[release]).forEach((pageId) => {
          const diff = differences[release][pageId];
          const page = pages.find((p) => p.id === pageId);
          if (!page) return;

          updateData[pageId] = {};

          if (diff.dataChanged) {
            const existingPageData = firestoreData[release]?.[pageId] || {};
            const existingDatasets = existingPageData.datasets || [];
            const existingDataset = existingDatasets[0] || {};

            updateData[pageId] = {
              ...existingPageData,
              labels: heuristics.map((h) => h.label),
              datasets: [
                {
                  ...existingDataset,
                  label: page.title,
                  data: diff.dataChanged.datasets[0].data,
                  borderColor: existingDataset.borderColor || page.color,
                  backgroundColor:
                    existingDataset.backgroundColor || page.color + "33",
                },
              ],
            };
          }

          if (diff.notesChanged) {
            updateData[pageId].notes = diff.notesChanged;
          }

          if (diff.linksChanged) {
            updateData[pageId].links = diff.linksChanged;
          }
        });

        batch.set(releaseRef, updateData, { merge: true });
      });

      await batch.commit();
      setLightboxMessage(
        "Nice! Data uploaded successfully! Keep up the good work!"
      );
      setUploadStatus("success");
      setUnsavedChanges(false);
      setModifiedFields({});
      onDataUpdate();
    } catch (error) {
      console.error("Error saving data:", error);
      setLightboxMessage("Oops, an error occurred. Please try again.");
      setUploadStatus("error");
    }
  };

  const handleCloneConfirm = async () => {
    if (!cloneSource || !selectedRelease) return;

    setUploadStatus("uploading");
    setLightboxMessage("Cloning release data...");
    setShowCloneSelect(false);

    setCloneInProgress(true);
    try {
      const sourceRef = doc(db, "releases", cloneSource);
      const currentRef = doc(db, "releases", selectedRelease);

      const [sourceSnap, currentSnap] = await Promise.all([
        getDoc(sourceRef),
        getDoc(currentRef),
      ]);

      if (!sourceSnap.exists()) {
        throw new Error("Source release not found");
      }

      const sourceData = sourceSnap.data();
      const currentData = currentSnap.exists() ? currentSnap.data() : {};
      const mergedData = {};

      // Handle page data merging
      for (const pageId of Object.keys(sourceData)) {
        if (
          typeof sourceData[pageId] !== "object" ||
          sourceData[pageId] === null
        ) {
          continue;
        }

        const sourcePage = sourceData[pageId];
        const currentPage = currentData[pageId] || {};

        // Special handling for datasets (heuristic values)
        const mergedDatasets = sourcePage.datasets?.map((sourceDataset) => {
          const currentDataset = currentPage.datasets?.find(
            (d) => d.label === sourceDataset.label
          );

          return {
            ...sourceDataset,
            data: sourceDataset.data.map((value, index) => {
              const currentValue = currentDataset?.data?.[index];
              return currentValue && currentValue !== 0 ? currentValue : value;
            }),
          };
        });

        mergedData[pageId] = {
          ...sourcePage,
          datasets: mergedDatasets,
        };
      }

      // Preserve existing notes and links, then add source data
      const finalData = {
        ...sourceData, // Base source data
        ...mergedData, // Merged page data with preserved heuristics
        notes: {
          ...sourceData.notes, // Source notes
          ...currentData.notes, // Preserve any existing notes
        },
        linkInputs: {
          ...sourceData.linkInputs, // Source links
          ...currentData.linkInputs, // Preserve any existing links
        },
        lastModified: new Date().toISOString(),
      };

      // Update Firestore
      await setDoc(currentRef, finalData);

      // Update all local states
      setFormData((prev) => ({
        ...prev,
        [selectedRelease]: finalData,
      }));

      // Update notes state
      setNotes((prev) => ({
        ...prev,
        [selectedRelease]: finalData.notes || {},
      }));

      // Update links state
      setLinkInputs((prev) => ({
        ...prev,
        [selectedRelease]: finalData.linkInputs || {},
      }));

      setLightboxMessage("Release data cloned successfully!");
      onDataUpdate();
      setShowUpdateButton(true);
    } catch (error) {
      console.error("Error cloning release:", error);
      setLightboxMessage("Error cloning release. Please try again.");
    } finally {
      setUploadStatus("idle");
      setCloneInProgress(false);
    }
  };

  const handleCancel = () => {
    if (showUpdateButton) {
      const confirmed = window.confirm(
        "Warning: Cloned data has not been saved, yet! Make sure to update data once all changes are ready for you. "
      );
      if (!confirmed) return;
    }
    setIsLightboxVisible(false);
    setShowCloneSelect(false);
    setCloneSource("");
    setShowUpdateButton(false);
    setCloneInProgress(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    setUploadStatus("uploading");
    setLightboxMessage("Updating data...");

    try {
      const currentRef = doc(db, "releases", selectedRelease);

      // Get current data for merging
      const currentSnap = await getDoc(currentRef);
      const currentData = currentSnap.exists() ? currentSnap.data() : {};

      // Merge current data with form data
      const updatedData = {
        ...currentData,
        ...formData[selectedRelease],
        lastModified: new Date().toISOString(),
      };

      // Update Firestore
      await setDoc(currentRef, updatedData);

      // Update local state
      setFormData((prev) => ({
        ...prev,
        [selectedRelease]: updatedData,
      }));

      setModifiedFields({});
      setUnsavedChanges(false);
      setLightboxMessage("Data updated successfully!");
      setShowUpdateButton(false);

      // Trigger parent component update
      onDataUpdate();
    } catch (error) {
      console.error("Error updating data:", error);
      setLightboxMessage("Error updating data. Please try again.");
    } finally {
      setUploadStatus("idle");
      setCloneInProgress(false);
    }
  };

  // Fetch dynamic pages from Firestore using real-time listener
  useEffect(() => {
    const pagesCollection = collection(db, "pages");
    const unsubscribePages = onSnapshot(pagesCollection, (snapshot) => {
      const pagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPages(pagesData);
      if (pagesData.length > 0 && !selectedPage) {
        setSelectedPage(pagesData[0].id);
      }
    });

    return () => {
      unsubscribePages();
    };
  }, [selectedPage]);

  // Load data from Firestore using real-time listener
  useEffect(() => {
    const releasesCollection = collection(db, "releases");
    const unsubscribeReleases = onSnapshot(releasesCollection, (snapshot) => {
      const loadedData = {};
      const loadedNotes = {};
      const loadedLinks = {};

      snapshot.forEach((doc) => {
        const releaseData = doc.data();
        const release = doc.id;
        loadedData[release] = {};

        // Iterate through pages
        Object.entries(releaseData).forEach(([pageId, pageData]) => {
          if (pageData.datasets?.[0]?.data) {
            // Retain the entire pageData including datasets with colors
            loadedData[release][pageId] = pageData;

            // Load notes
            if (pageData.notes) {
              if (!loadedNotes[pageId]) loadedNotes[pageId] = {};
              loadedNotes[pageId][release] = pageData.notes;
            }

            // Load links
            if (pageData.links) {
              if (!loadedLinks[pageId]) loadedLinks[pageId] = {};
              if (!loadedLinks[pageId][release])
                loadedLinks[pageId][release] = {};
              Object.entries(pageData.links).forEach(([index, links]) => {
                if (links && links.length > 0) {
                  loadedLinks[pageId][release][index] = links;
                }
              });
            }
          }
        });
      });

      setFirestoreData(loadedData); // Update latest data from Firestore

      // Merge Firestore data with local state without overwriting modified fields
      if (!isMergingRef.current) {
        isMergingRef.current = true;
        setFormData((prevFormData) => {
          return mergeFirestoreData(prevFormData, loadedData, modifiedFields);
        });
        setNotes((prevNotes) => {
          return mergeNotes(prevNotes, loadedNotes, modifiedFields);
        });
        setLinkInputs((prevLinks) => {
          return mergeLinkInputs(prevLinks, loadedLinks, modifiedFields);
        });
        setNoteInputs((prevNotes) => {
          return mergeNotes(prevNotes, loadedNotes, modifiedFields);
        });
        isMergingRef.current = false;
      }

      const releaseKeys = Object.keys(loadedData);
      setReleasesList(releaseKeys.sort((a, b) => b.localeCompare(a))); // Sort in descending order
      if (releaseKeys.length > 0 && !selectedRelease) {
        setSelectedRelease(releaseKeys[0]);
      }
    });

    return () => {
      unsubscribeReleases();
    };
  }, [selectedRelease, modifiedFields]);

  // const isParentPage = (pageId) => {
  //   return pages.some((page) => page.parentPage === pageId);
  // };

  // Helper function to determine if the selected page is a parent page
  const isPageParent = (pageId) => {
    // Check if any page has the current page as its parentPage (indicating this page has children)
    const hasChildren = pages.some((page) => page.parentPage === pageId);
    return hasChildren;
  };

  // Helper function to determine if the selected page is a child page
  const isPageChild = (pageId) => {
    // Check if the selected page itself has a parentPage property (indicating it is a child)
    const page = pages.find((p) => p.id === pageId);
    return page?.parentPage !== undefined;
  };

  useEffect(() => {
    if (selectedPage) {
      const isParent = isPageParent(selectedPage);
      setIsParentPage(isParent); // Set the state to track if the page is a parent
    }
  }, [selectedPage, pages]);

  const [isChildPage, setIsChildPage] = useState(false);

  useEffect(() => {
    if (selectedPage) {
      const isParent = isPageParent(selectedPage);
      const isChild = isPageChild(selectedPage);
      setIsParentPage(isParent);
      setIsChildPage(isChild);
    }
  }, [selectedPage, pages]);

  // Handler to add a new note
  const handleAddNote = (pageId, release, heuristicIndex) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].notes)
        newModified[release][pageId].notes = {};
      newModified[release][pageId].notes[heuristicIndex] = true;
      return newModified;
    });

    // Use a functional update to avoid race conditions with state updates
    setNoteInputs((prev) => {
      const newInputs = { ...prev };
      if (!newInputs[pageId]) newInputs[pageId] = {};
      if (!newInputs[pageId][release]) newInputs[pageId][release] = {};
      if (!Array.isArray(newInputs[pageId][release][heuristicIndex])) {
        newInputs[pageId][release][heuristicIndex] = []; // Ensure it's initialized properly
      }

      // Add a new note only if it's not already there to prevent duplication
      if (
        newInputs[pageId][release][heuristicIndex].length === 0 ||
        newInputs[pageId][release][heuristicIndex][
          newInputs[pageId][release][heuristicIndex].length - 1
        ] !== ""
      ) {
        newInputs[pageId][release][heuristicIndex].push("");
      }
      return newInputs;
    });
  };

  // Handler to change a note value
  const handleNoteChange = (
    pageId,
    release,
    heuristicIndex,
    noteIndex,
    value
  ) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].notes)
        newModified[release][pageId].notes = {};
      newModified[release][pageId].notes[heuristicIndex] = true;
      return newModified;
    });
    setNoteInputs((prev) => {
      const newInputs = { ...prev };
      if (!newInputs[pageId]) newInputs[pageId] = {};
      if (!newInputs[pageId][release]) newInputs[pageId][release] = {};
      if (!newInputs[pageId][release][heuristicIndex]) {
        newInputs[pageId][release][heuristicIndex] = [];
      }
      newInputs[pageId][release][heuristicIndex][noteIndex] = value;
      return newInputs;
    });
  };

  // Handler to remove a note
  const handleRemoveNote = (pageId, release, heuristicIndex, noteIndex) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].notes)
        newModified[release][pageId].notes = {};
      newModified[release][pageId].notes[heuristicIndex] = true;
      return newModified;
    });
    setNoteInputs((prev) => {
      const newInputs = { ...prev };
      if (newInputs[pageId]?.[release]?.[heuristicIndex]) {
        newInputs[pageId][release][heuristicIndex].splice(noteIndex, 1);
      }
      return newInputs;
    });
  };

  // handle search term change
  const handlePageSearchChange = (e) => {
    setPageSearchTerm(e.target.value);
  };

  const filteredPages = pages.filter((page) =>
    page.title.toLowerCase().includes(pageSearchTerm.toLowerCase())
  );

  // Function to merge Firestore data with local state without overwriting modified fields
  const mergeFirestoreData = (
    localData,
    firestoreData,
    modifiedFields,
    isNotesOrLinks = false
  ) => {
    const mergedData = { ...localData };

    Object.keys(firestoreData).forEach((release) => {
      Object.keys(firestoreData[release] || {}).forEach((pageId) => {
        if (!mergedData[release]) mergedData[release] = {};
        if (!modifiedFields[release]?.[pageId]) {
          mergedData[release][pageId] = firestoreData[release][pageId];
        } else {
          const modifiedHeuristics = modifiedFields[release][pageId].data || {};
          const existingPageData = mergedData[release][pageId] || {
            datasets: [{ data: [] }],
          };

          // Retain existing datasets and only update unmodified data points
          const mergedDatasets = existingPageData.datasets.map(
            (dataset, idx) => {
              const firestoreDataset =
                firestoreData[release][pageId].datasets[idx];
              const data = [...dataset.data];
              firestoreDataset.data.forEach((value, index) => {
                if (!modifiedHeuristics[index]) {
                  data[index] = value;
                }
              });
              return {
                ...dataset,
                ...firestoreDataset,
                data,
              };
            }
          );

          mergedData[release][pageId] = {
            ...existingPageData,
            ...firestoreData[release][pageId],
            datasets: mergedDatasets,
          };
        }
      });
    });

    return mergedData;
  };

  // New merge function for notes
  // New merge function for notes
  const mergeNotes = (prevNotes, loadedNotes, modifiedFields) => {
    const mergedNotes = { ...prevNotes };

    Object.keys(loadedNotes).forEach((pageId) => {
      if (!mergedNotes[pageId]) mergedNotes[pageId] = {};
      Object.keys(loadedNotes[pageId]).forEach((release) => {
        if (!modifiedFields[release]?.[pageId]?.notes) {
          // Check if the note value is a string and convert to an array
          mergedNotes[pageId][release] = {};

          Object.keys(loadedNotes[pageId][release]).forEach(
            (heuristicIndex) => {
              const noteData = loadedNotes[pageId][release][heuristicIndex];
              mergedNotes[pageId][release][heuristicIndex] =
                typeof noteData === "string" ? [noteData] : noteData;
            }
          );
        }
      });
    });

    return mergedNotes;
  };

  // New merge function for linkInputs
  const mergeLinkInputs = (prevLinks, loadedLinks, modifiedFields) => {
    const mergedLinks = { ...prevLinks };

    Object.keys(loadedLinks).forEach((pageId) => {
      if (!mergedLinks[pageId]) mergedLinks[pageId] = {};
      Object.keys(loadedLinks[pageId]).forEach((release) => {
        if (!mergedLinks[pageId][release]) mergedLinks[pageId][release] = {};
        Object.keys(loadedLinks[pageId][release]).forEach((heuristicIndex) => {
          if (!modifiedFields[release]?.[pageId]?.links?.[heuristicIndex]) {
            mergedLinks[pageId][release][heuristicIndex] =
              loadedLinks[pageId][release][heuristicIndex];
          }
        });
      });
    });

    return mergedLinks;
  };

  // Handle clicks outside of flyouts and settings
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".settings-container")) {
        setIsSettingsOpen(false);
      }
      if (!event.target.closest(".flyout-container")) {
        setIsReleaseFlyoutOpen(false);
        setIsPageFlyoutOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle input changes in the form
  const handleInputChange = (
    release,
    pageId,
    heuristicIndex,
    value,
    isNote = false
  ) => {
    setUnsavedChanges(true);

    // Update modifiedFields
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (isNote) {
        if (!newModified[release][pageId].notes)
          newModified[release][pageId].notes = {};
        newModified[release][pageId].notes[heuristicIndex] = true;
      } else {
        if (!newModified[release][pageId].data)
          newModified[release][pageId].data = {};
        newModified[release][pageId].data[heuristicIndex] = true;
      }
      return newModified;
    });

    if (isNote) {
      setNotes((prevNotes) => ({
        ...prevNotes,
        [`${release}-${pageId}-${heuristicIndex}`]: value,
      }));
    } else {
      setFormData((prevData) => {
        const releaseData = prevData[release] || {};
        const pageData = releaseData[pageId] || {
          labels: heuristics.map((h) => h.label),
          datasets: [
            {
              data: new Array(heuristics.length).fill(0),
              borderColor:
                pages.find((p) => p.id === pageId)?.color || "#000000",
              backgroundColor:
                (pages.find((p) => p.id === pageId)?.color || "#000000") + "33",
              label: pages.find((p) => p.id === pageId)?.title || "Page",
            },
          ],
        };

        const updatedData = [...pageData.datasets[0].data];
        updatedData[heuristicIndex] = Number(value);

        return {
          ...prevData,
          [release]: {
            ...releaseData,
            [pageId]: {
              ...pageData,
              datasets: [
                {
                  ...pageData.datasets[0],
                  data: updatedData,
                },
              ],
            },
          },
        };
      });
    }
  };

  // Function to compare and get differences between two objects
  const getDifferences = (
    oldData,
    newData,
    oldNotes,
    newNotes,
    oldLinks,
    newLinks
  ) => {
    const differences = {};

    const allReleases = new Set([
      ...Object.keys(newData),
      ...Object.keys(oldData),
    ]);

    allReleases.forEach((release) => {
      const oldReleaseData = oldData[release] || {};
      const newReleaseData = newData[release] || {};
      const allPages = new Set([
        ...Object.keys(newReleaseData),
        ...Object.keys(oldReleaseData),
      ]);

      allPages.forEach((pageId) => {
        const oldPageData = oldReleaseData[pageId]?.datasets?.[0]?.data || [];
        const newPageData = newReleaseData[pageId]?.datasets?.[0]?.data || [];

        const hasDataChanged =
          JSON.stringify(oldPageData) !== JSON.stringify(newPageData);

        // Compare notes
        const oldNotesForPage = oldNotes?.[pageId]?.[release] || {};
        const newNotesForPage = newNotes?.[pageId]?.[release] || {};
        const hasNotesChanged =
          JSON.stringify(oldNotesForPage) !== JSON.stringify(newNotesForPage);

        // Compare links
        const oldLinksForPage = oldLinks[pageId]?.[release] || {};
        const newLinksForPage = newLinks[pageId]?.[release] || {};
        const hasLinksChanged =
          JSON.stringify(oldLinksForPage) !== JSON.stringify(newLinksForPage);

        if (hasDataChanged || hasNotesChanged || hasLinksChanged) {
          if (!differences[release]) differences[release] = {};
          differences[release][pageId] = {
            dataChanged: hasDataChanged ? newReleaseData[pageId] : undefined,
            notesChanged: hasNotesChanged ? newNotesForPage : undefined,
            linksChanged: hasLinksChanged ? newLinksForPage : undefined,
          };
        }
      });
    });

    return differences;
  };

  // Submit data to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();
    await performDataUpdate();
  };

  // Adds a new release to Firestore
  const handleAddRelease = async (e) => {
    e.preventDefault();
    const sanitizedNewRelease = newRelease.trim();
    const invalidChars = /[./#$\[\]]/;

    if (sanitizedNewRelease && !releasesList.includes(sanitizedNewRelease)) {
      if (invalidChars.test(sanitizedNewRelease)) {
        alert("Release number contains invalid characters.");
        return;
      }

      // Create initial data for the new release
      const newReleaseData = {};
      pages.forEach((page) => {
        const pageId = page.id;
        const pageTitle = page.title;
        newReleaseData[pageId] = {
          labels: heuristics.map((h) => h.label),
          datasets: [
            {
              label: pageTitle,
              data: new Array(heuristics.length).fill(0),
              borderColor: page.color,
              backgroundColor: page.color + "33",
              fill: false,
              tension: 0.1,
            },
          ],
        };
      });
      try {
        // Save the new release to Firestore
        const releaseDocRef = doc(db, "releases", sanitizedNewRelease);
        await setDoc(releaseDocRef, newReleaseData);
        setFormData((prevData) => ({
          ...prevData,
          [sanitizedNewRelease]: newReleaseData,
        }));
        setSelectedRelease(sanitizedNewRelease);
        setReleasesList((prevList) => [...prevList, sanitizedNewRelease]);
        setNewRelease("");
        onDataUpdate();
      } catch (error) {
        console.error("Error adding new release:", error);
        alert(`Error adding new release: ${error.message}`);
      }
    } else {
      alert("Invalid or duplicate release number");
    }
  };

  // Deletes a release from Firestore
  const handleDeleteRelease = async (releaseId) => {
    if (
      window.confirm(`Are you sure you want to delete release ${releaseId}?`)
    ) {
      try {
        const releaseDocRef = doc(db, "releases", releaseId);
        await deleteDoc(releaseDocRef);
        setFormData((prevData) => {
          const newData = { ...prevData };
          delete newData[releaseId];
          return newData;
        });
        setReleasesList((prevList) =>
          prevList.filter((release) => release !== releaseId)
        );
        if (selectedRelease === releaseId) {
          setSelectedRelease(releasesList.length > 1 ? releasesList[0] : "");
        }
        onDataUpdate();
        alert(`Release ${releaseId} deleted successfully.`);
      } catch (error) {
        console.error("Error deleting release:", error);
        alert(`Error deleting release: ${error.message}`);
      }
    }
  };

  // Generates random data for the selected release and page
  const generateRandomData = useCallback(
    (e) => {
      e.preventDefault(); // Prevent form submission
      setUnsavedChanges(true);
      setModifiedFields((prev) => {
        const newModified = { ...prev };
        if (!newModified[selectedRelease]) newModified[selectedRelease] = {};
        if (!newModified[selectedRelease][selectedPage])
          newModified[selectedRelease][selectedPage] = { data: {} };
        heuristics.forEach((_, index) => {
          newModified[selectedRelease][selectedPage].data[index] = true;
        });
        return newModified;
      });
      setFormData((prevData) => {
        const newData = { ...prevData };
        if (
          newData[selectedRelease] &&
          newData[selectedRelease][selectedPage]
        ) {
          let prevValue = Math.random() * 5; // Initial random value
          const updatedData = newData[selectedRelease][
            selectedPage
          ].datasets[0].data.map(() => {
            const newValue = Math.max(
              0,
              Math.min(5, prevValue + (Math.random() * 2 - 1))
            );
            prevValue = newValue;
            return Number(newValue.toFixed(1));
          });
          newData[selectedRelease][selectedPage].datasets[0].data = updatedData;
        }
        return newData;
      });
    },
    [selectedRelease, selectedPage]
  );

  // Generates random data for all releases for the selected page
  const generateRandomDataForAllReleases = useCallback(() => {
    if (!selectedPage) {
      alert("Please select a page first.");
      return;
    }
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      releasesList.forEach((release) => {
        if (!newModified[release]) newModified[release] = {};
        if (!newModified[release][selectedPage])
          newModified[release][selectedPage] = { data: {} };
        heuristics.forEach((_, index) => {
          newModified[release][selectedPage].data[index] = true;
        });
      });
      return newModified;
    });
    setFormData((prevData) => {
      const newData = { ...prevData };
      releasesList.forEach((release) => {
        if (!newData[release]) {
          newData[release] = {};
        }
        if (!newData[release][selectedPage]) {
          newData[release][selectedPage] = {
            datasets: [{ data: new Array(heuristics.length).fill(0) }],
          };
        }

        let prevValue = Math.random() * 5; // Initial random value

        const updatedData = newData[release][selectedPage].datasets[0].data.map(
          () => {
            const newValue = Math.max(
              0,
              Math.min(5, prevValue + (Math.random() * 2 - 1))
            );
            prevValue = newValue;
            return Number(newValue.toFixed(1));
          }
        );

        newData[release][selectedPage].datasets[0].data = updatedData;
      });
      return newData;
    });
  }, [selectedPage, releasesList]);

  // Generates random data for all releases and all pages
  const generateRandomDataForEverything = useCallback(() => {
    if (
      window.confirm(
        "Are you sure you want to generate random data for all releases and all pages?"
      )
    ) {
      setUnsavedChanges(true);
      setModifiedFields((prev) => {
        const newModified = { ...prev };
        releasesList.forEach((release) => {
          if (!newModified[release]) newModified[release] = {};
          pages.forEach((page) => {
            if (!newModified[release][page.id])
              newModified[release][page.id] = { data: {} };
            heuristics.forEach((_, index) => {
              newModified[release][page.id].data[index] = true;
            });
          });
        });
        return newModified;
      });
      setFormData((prevData) => {
        const newData = { ...prevData };

        releasesList.forEach((release) => {
          if (!newData[release]) {
            newData[release] = {};
          }

          pages.forEach((page) => {
            if (!newData[release][page.id]) {
              newData[release][page.id] = {
                datasets: [{ data: new Array(heuristics.length).fill(0) }],
              };
            }

            let prevValue = Math.random() * 5; // Initial random value

            const updatedData = newData[release][page.id].datasets[0].data.map(
              () => {
                const newValue = Math.max(
                  0,
                  Math.min(5, prevValue + (Math.random() * 2 - 1))
                );
                prevValue = newValue;
                return Number(newValue.toFixed(1));
              }
            );

            newData[release][page.id].datasets[0].data = updatedData;
          });
        });

        return newData;
      });
    }
  }, [releasesList, pages]);

  // Helper function to organize pages into a hierarchical structure
  const organizePages = (pages) => {
    const pageMap = new Map();
    const rootPages = [];

    pages.forEach((page) => {
      pageMap.set(page.id, { ...page, children: [] });
    });

    pages.forEach((page) => {
      if (page.parentPage) {
        const parentPage = pageMap.get(page.parentPage);
        if (parentPage) {
          parentPage.children.push(pageMap.get(page.id));
        } else {
          rootPages.push(pageMap.get(page.id));
        }
      } else {
        rootPages.push(pageMap.get(page.id));
      }
    });

    return rootPages;
  };

  // Recursive function to render nested pages
  const renderNestedPages = (pages, level = 0) => {
    return pages.map((page) => (
      <React.Fragment key={page.id}>
        <div className={`flyout-menu-item ${level > 0 ? "nested-item" : ""}`}>
          <span
            onClick={() => {
              setSelectedPage(page.id);
              setIsPageFlyoutOpen(false);
            }}
          >
            {page.title}
          </span>
          <FontAwesomeIcon
            icon={faTrash}
            className="delete-page-icon"
            onClick={() => handleDeletePage(page.id, page.title)}
          />
        </div>
        {page.children &&
          page.children.length > 0 &&
          renderNestedPages(page.children, level + 1)}
      </React.Fragment>
    ));
  };

  // Deletes a page and its data from Firestore
  const handleDeletePage = async (pageId, pageTitle) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete the page "${pageTitle}"? This action cannot be undone.`
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const pageRef = doc(db, "pages", pageId);
      await deleteDoc(pageRef);

      const releasesSnapshot = await getDocs(collection(db, "releases"));

      for (const releaseDoc of releasesSnapshot.docs) {
        const releaseData = releaseDoc.data();

        if (releaseData[pageId]) {
          const releaseRef = doc(db, "releases", releaseDoc.id);
          await updateDoc(releaseRef, {
            [pageId]: deleteField(),
          });
        }
      }

      setPages((prevPages) => prevPages.filter((page) => page.id !== pageId));
      setFormData((prevData) => {
        const newData = { ...prevData };
        Object.keys(newData).forEach((release) => {
          if (newData[release][pageId]) {
            delete newData[release][pageId];
          }
        });
        return newData;
      });

      alert(`Page "${pageTitle}" and its data have been deleted.`);
    } catch (error) {
      console.error("Error deleting page and its data from releases:", error);
      alert("An error occurred while deleting the page. Please try again.");
    }
  };

  // Link add handler:
  const handleAddLink = (pageId, release, heuristicIndex) => {
    setLinkInputs((prev) => {
      const newInputs = { ...prev };
      if (!newInputs[pageId]) newInputs[pageId] = {};
      if (!newInputs[pageId][release]) newInputs[pageId][release] = {};
      if (!Array.isArray(newInputs[pageId][release][heuristicIndex])) {
        newInputs[pageId][release][heuristicIndex] = [];
      }
      newInputs[pageId][release][heuristicIndex].push("");
      return newInputs;
    });
  };

  // Handles link input changes
  const handleLinkChange = (
    pageId,
    release,
    heuristicIndex,
    linkIndex,
    value
  ) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].links)
        newModified[release][pageId].links = {};
      newModified[release][pageId].links[heuristicIndex] = true;
      return newModified;
    });
    setLinkInputs((prev) => {
      const newInputs = { ...prev };
      if (!newInputs[pageId]) newInputs[pageId] = {};
      if (!newInputs[pageId][release]) newInputs[pageId][release] = {};
      if (!newInputs[pageId][release][heuristicIndex]) {
        newInputs[pageId][release][heuristicIndex] = [];
      }
      newInputs[pageId][release][heuristicIndex][linkIndex] = value;
      return newInputs;
    });
  };

  // Removes a link
  const handleRemoveLink = async (
    pageId,
    release,
    heuristicIndex,
    linkIndex
  ) => {
    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[release]) newModified[release] = {};
      if (!newModified[release][pageId]) newModified[release][pageId] = {};
      if (!newModified[release][pageId].links)
        newModified[release][pageId].links = {};
      newModified[release][pageId].links[heuristicIndex] = true;
      return newModified;
    });
    setLinkInputs((prev) => {
      const newInputs = { ...prev };
      if (newInputs[pageId]?.[release]?.[heuristicIndex]) {
        newInputs[pageId][release][heuristicIndex].splice(linkIndex, 1);
      }
      return newInputs;
    });
  };

  const populateParentLinksFromChildren = (parentPageId, releaseId) => {
    const childPages = pages.filter((page) => page.parentPage === parentPageId);

    if (childPages.length === 0) {
      alert("No child pages available to aggregate links from.");
      return;
    }

    setLinkInputs((prev) => {
      const newLinkInputs = { ...prev };
      if (!newLinkInputs[parentPageId]) newLinkInputs[parentPageId] = {};
      if (!newLinkInputs[parentPageId][releaseId])
        newLinkInputs[parentPageId][releaseId] = {};

      heuristics.forEach((_, heuristicIndex) => {
        // Initialize or clear the parent links array for this heuristic
        newLinkInputs[parentPageId][releaseId][heuristicIndex] = [];

        childPages.forEach((childPage) => {
          const childLinks =
            linkInputs[childPage.id]?.[releaseId]?.[heuristicIndex] || [];
          newLinkInputs[parentPageId][releaseId][heuristicIndex] = [
            ...newLinkInputs[parentPageId][releaseId][heuristicIndex],
            ...childLinks,
          ];
        });
      });

      return newLinkInputs;
    });

    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[releaseId]) newModified[releaseId] = {};
      if (!newModified[releaseId][parentPageId])
        newModified[releaseId][parentPageId] = {};
      if (!newModified[releaseId][parentPageId].links)
        newModified[releaseId][parentPageId].links = {};
      newModified[releaseId][parentPageId].links = true;

      return newModified;
    });
  };

  const populateParentNotesFromChildren = (parentPageId, releaseId) => {
    const childPages = pages.filter((page) => page.parentPage === parentPageId);

    if (childPages.length === 0) {
      alert("No child pages available to aggregate notes from.");
      return;
    }

    setNoteInputs((prev) => {
      const newNoteInputs = { ...prev };
      if (!newNoteInputs[parentPageId]) newNoteInputs[parentPageId] = {};
      if (!newNoteInputs[parentPageId][releaseId])
        newNoteInputs[parentPageId][releaseId] = {};

      heuristics.forEach((_, heuristicIndex) => {
        // Initialize or clear the parent notes array for this heuristic
        newNoteInputs[parentPageId][releaseId][heuristicIndex] = [];

        childPages.forEach((childPage) => {
          const childNotes =
            noteInputs[childPage.id]?.[releaseId]?.[heuristicIndex] || [];
          newNoteInputs[parentPageId][releaseId][heuristicIndex] = [
            ...newNoteInputs[parentPageId][releaseId][heuristicIndex],
            ...childNotes,
          ];
        });
      });

      return newNoteInputs;
    });

    setUnsavedChanges(true);
    setModifiedFields((prev) => {
      const newModified = { ...prev };
      if (!newModified[releaseId]) newModified[releaseId] = {};
      if (!newModified[releaseId][parentPageId])
        newModified[releaseId][parentPageId] = {};
      newModified[releaseId][parentPageId].notes = true;

      return newModified;
    });
  };

  // Calculates parent averages
  const calculateParentAverages = () => {
    const updatedFormData = { ...formData }; // Create a copy of formData

    releasesList.forEach((release) => {
      if (formData[release]) {
        const parentPages = pages.filter((page) => !page.parentPage);

        parentPages.forEach((parentPage) => {
          const childPages = pages.filter(
            (page) => page.parentPage === parentPage.id
          );

          if (childPages.length === 0) {
            // No children, set to zero scores
            const zeroScores = new Array(heuristics.length).fill(0);

            if (!updatedFormData[release][parentPage.id]) {
              updatedFormData[release][parentPage.id] = {
                labels: heuristics.map((h) => h.label),
                datasets: [
                  {
                    label: parentPage.title,
                    data: zeroScores,
                    borderColor: parentPage.color || "#000000",
                    backgroundColor: `${parentPage.color || "#000000"}33`,
                  },
                ],
              };
            } else {
              updatedFormData[release][parentPage.id].datasets[0].data =
                zeroScores;
            }
          } else {
            // Aggregate scores from child pages
            const totalScores = new Array(heuristics.length).fill(0);
            let validChildPages = 0;

            childPages.forEach((childPage) => {
              const childScores =
                formData[release][childPage.id]?.datasets?.[0]?.data || null;

              if (childScores) {
                validChildPages++;
                childScores.forEach((score, index) => {
                  totalScores[index] += parseFloat(score) || 0;
                });
              }
            });

            if (validChildPages > 0) {
              const averageScores = totalScores.map((total) =>
                (total / validChildPages).toFixed(1)
              );

              if (!updatedFormData[release][parentPage.id]) {
                updatedFormData[release][parentPage.id] = {
                  labels: heuristics.map((h) => h.label),
                  datasets: [
                    {
                      label: parentPage.title,
                      data: averageScores.map(Number),
                      borderColor: parentPage.color || "#000000",
                      backgroundColor: `${parentPage.color || "#000000"}33`,
                    },
                  ],
                };
              } else {
                updatedFormData[release][parentPage.id].datasets[0].data =
                  averageScores.map(Number);
              }
            }
          }
        });
      }
    });

    setFormData(updatedFormData); // Update state with the calculated averages
  };

  // Set the selected page when the component mounts
  useEffect(() => {
    if (pageId) {
      setSelectedPage(pageId);
    }
  }, [pageId]);

  // Add compareVersions function
  function compareVersions(a, b) {
    const cleanA = a.replace(/^Release /, "");
    const cleanB = b.replace(/^Release /, "");

    const [aMajor, aMinor] = cleanA.split(".").map(Number);
    const [bMajor, bMinor] = cleanB.split(".").map(Number);

    // Compare major versions first
    if (aMajor !== bMajor) {
      return bMajor - aMajor;
    }
    // Compare minor versions numerically
    return bMinor - aMinor;
  }

  // Update useEffect for fetching and sorting releases
  useEffect(() => {
    const fetchReleases = async () => {
      try {
        const releasesCollection = collection(db, "releases");
        const releasesSnapshot = await getDocs(releasesCollection);
        const releases = releasesSnapshot.docs.map((doc) => doc.id);

        // Sort releases using the comparator
        const sortedReleases = releases.sort(compareVersions);

        setReleasesList(sortedReleases); // Store sorted releases in state
        setSelectedRelease(sortedReleases[0]); // Default to the highest release
      } catch (error) {
        console.error("Error fetching releases:", error);
      }
    };

    fetchReleases();
  }, []);

  // Add useEffect to monitor state changes
  // useEffect(() => {
  //   console.log("showCloneSelect changed:", showCloneSelect);
  // }, [showCloneSelect]);

  return (
    <div className="data-entry-container">
      <div className="data-entry-header">
        <div className="release-selector">
          <div className="release-dropdown">
            <div
              className="dropdown-header"
              onClick={() => setIsReleaseFlyoutOpen(!isReleaseFlyoutOpen)}
            >
              <span>
                {selectedRelease
                  ? `Release ${selectedRelease}`
                  : "Select Release"}
              </span>
              <FontAwesomeIcon
                icon={isReleaseFlyoutOpen ? faChevronUp : faChevronDown}
              />
            </div>
            {isReleaseFlyoutOpen && (
              <div className="flyout-container">
                <div className="flyout-menu">
                  {releasesList.length > 0 ? (
                    releasesList
                      .slice() // Create a copy to avoid mutating state
                      .sort(compareVersions) // Sort again just to ensure order
                      .map((release) => (
                        <div key={release} className="flyout-menu-item">
                          <span
                            onClick={() => {
                              setSelectedRelease(release);
                              setIsReleaseFlyoutOpen(false);
                            }}
                          >
                            Release {release}
                          </span>

                          {isSuperAdmin && (
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="delete-release-icon"
                              onClick={() => handleDeleteRelease(release)}
                            />
                          )}
                        </div>
                      ))
                  ) : (
                    <div>No Releases Available</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="page-selector">
          <div className="page-dropdown">
            <div
              className="dropdown-header"
              onClick={() => setIsPageFlyoutOpen(!isPageFlyoutOpen)}
            >
              <span>
                {pages.find((p) => p.id === selectedPage)?.title ||
                  "Select Page"}
              </span>
              <FontAwesomeIcon
                icon={isPageFlyoutOpen ? faChevronUp : faChevronDown}
              />
            </div>
            {isPageFlyoutOpen && (
              <div className="flyout-container" key={selectedRelease}>
                <div className="flyout-menu">
                  <div className="search-input-container">
                    <input
                      type="text"
                      placeholder="Search Pages..."
                      value={pageSearchTerm}
                      onChange={handlePageSearchChange}
                      className="page-search-input"
                    />
                    {pageSearchTerm && (
                      <button
                        type="button"
                        className="clear-search-btn"
                        onClick={() => setPageSearchTerm("")}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </button>
                    )}
                  </div>

                  {filteredPages.length > 0 ? (
                    renderNestedPages(organizePages(filteredPages))
                  ) : (
                    <div>No Pages Found</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        className="data-entry-form"
      >
        {selectedRelease && selectedPage ? (
          <div className="form-section">
            <h3>
              Release {selectedRelease} -{" "}
              {pages.find((p) => p.id === selectedPage)?.title}
            </h3>
            {heuristics.map((heuristic, hIndex) => (
              <div key={hIndex} className="form-group">
                <label
                  htmlFor={`heuristic-${selectedRelease}-${selectedPage}-${hIndex}`}
                >
                  {heuristic.label}:
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    id={`heuristic-${selectedRelease}-${selectedPage}-${hIndex}`}
                    min="0"
                    max="5"
                    step="0.1"
                    onWheel={(e) => e.preventDefault()}
                    onFocus={(e) => (e.target.value = "")}
                    value={
                      formData[selectedRelease]?.[selectedPage]?.datasets?.[0]
                        ?.data?.[hIndex] ?? 0
                    }
                    onChange={(e) =>
                      handleInputChange(
                        selectedRelease,
                        selectedPage,
                        hIndex,
                        e.target.value
                      )
                    }
                    required
                  />
                  <div className="notes-container">
                    {(
                      noteInputs[selectedPage]?.[selectedRelease]?.[hIndex] ||
                      []
                    ).map((note, noteIndex) => (
                      <div key={noteIndex} className="note-input-row">
                        <textarea
                          value={note || ""}
                          onChange={(e) =>
                            handleNoteChange(
                              selectedPage,
                              selectedRelease,
                              hIndex,
                              noteIndex,
                              e.target.value
                            )
                          }
                          placeholder="Add note..."
                          className="heuristic-notes"
                        />
                        <div className="note-actions">
                          {noteIndex ===
                            noteInputs[selectedPage][selectedRelease][hIndex]
                              ?.length -
                              1 && (
                            <button
                              type="button"
                              className="icon-btn"
                              onClick={() =>
                                handleAddNote(
                                  selectedPage,
                                  selectedRelease,
                                  hIndex
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          )}
                          <button
                            type="button"
                            className="icon-btn"
                            onClick={() =>
                              handleRemoveNote(
                                selectedPage,
                                selectedRelease,
                                hIndex,
                                noteIndex
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </div>
                    ))}
                    {/* Display Add Note button if no notes exist */}
                    {(!noteInputs[selectedPage]?.[selectedRelease]?.[hIndex] ||
                      noteInputs[selectedPage][selectedRelease][hIndex]
                        .length === 0) && (
                      <button
                        type="button"
                        className="initial-add-note"
                        onClick={() =>
                          handleAddNote(selectedPage, selectedRelease, hIndex)
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add Note
                      </button>
                    )}
                  </div>
                </div>
                <div className="links-container">
                  {(Array.isArray(
                    linkInputs[selectedPage]?.[selectedRelease]?.[hIndex]
                  )
                    ? linkInputs[selectedPage][selectedRelease][hIndex]
                    : []
                  ).map((link, linkIndex) => (
                    <div key={linkIndex} className="link-input-row">
                      <input
                        type="url"
                        value={link || ""}
                        onChange={(e) =>
                          handleLinkChange(
                            selectedPage,
                            selectedRelease,
                            hIndex,
                            linkIndex,
                            e.target.value
                          )
                        }
                        placeholder="Enter URL"
                      />
                      <div className="link-actions">
                        <button
                          type="button"
                          className="icon-btn"
                          onClick={() =>
                            handleAddLink(selectedPage, selectedRelease, hIndex)
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                        <button
                          type="button"
                          className="icon-btn"
                          onClick={() =>
                            handleRemoveLink(
                              selectedPage,
                              selectedRelease,
                              hIndex,
                              linkIndex
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </div>
                  ))}
                  {(!linkInputs[selectedPage]?.[selectedRelease]?.[hIndex] ||
                    linkInputs[selectedPage][selectedRelease][hIndex].length ===
                      0) && (
                    <button
                      type="button"
                      className="initial-add-link"
                      onClick={() =>
                        handleAddLink(selectedPage, selectedRelease, hIndex)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Link
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Please select a release and page to enter data.</p>
        )}
        <div className="settings-container">
          {/* <button
            type="button"
            className="settings-icon"
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          >
            <FontAwesomeIcon icon={faCog} />
          </button> */}

          {/* {isSettingsOpen && (
            <div className="flyout-container">
              <div className="settings-flyout">
                <button
                  type="button"
                  className="generate-data-btn"
                  onClick={generateRandomData}
                >
                  Generate Random Data
                </button>
                <button
                  type="button"
                  className="generate-data-btn"
                  onClick={generateRandomDataForAllReleases}
                >
                  Generate Random Data (All Releases)
                </button>
                <button
                  type="button"
                  onClick={generateRandomDataForEverything}
                  className="generate-data-btn"
                >
                  Generate Random Data for Everything
                </button>
              </div>
            </div>
          )} */}
        </div>
        {/* Update DataEntryForm.js Lightbox usage - pass as props instead of childern */}
        <Lightbox
          isVisible={isLightboxVisible}
          onClose={() => {
            setIsLightboxVisible(false);
            setShowCloneSelect(false);
            setCloneSource("");
          }}
          message={lightboxMessage}
          isLoading={uploadStatus === "uploading"}
          showCloseButton={!showCloneSelect && uploadStatus !== "uploading"}
          showCloneSelect={showCloneSelect}
          cloneSelect={
            <div className="clone-select-container">
              <select
                value={cloneSource}
                onChange={(e) => setCloneSource(e.target.value)}
                className="clone-select"
              >
                <option value="">Select Release</option>
                {releasesList
                  .filter((release) => release !== selectedRelease)
                  .map((release) => (
                    <option key={release} value={release}>
                      {release}
                    </option>
                  ))}
              </select>
            </div>
          }
          onConfirm={handleCloneConfirm}
          onCancel={handleCancel}
          onUpdate={performDataUpdate} // Ensure Lightbox uses the same function
          showUpdateButton={showUpdateButton}
          cloneInProgress={cloneInProgress}
        />

        <div className="form-actions">
          <button type="submit" className="submit-button data-entry-btn">
            Update Data
          </button>

          {isSuperAdmin && (
            <button
              type="button"
              className="clone-btn data-entry-btn"
              onClick={handleCloneClick}
            >
              <FontAwesomeIcon icon={faCopy} />
              Clone Data
            </button>
          )}
          <button
            type="button"
            className="calculate-btn data-entry-btn"
            onClick={calculateParentAverages}
            disabled={!isParentPage} // Disable if the selected page is a child
          >
            {isCalculating ? (
              "Calculating..."
            ) : (
              <>
                <FontAwesomeIcon icon={faCalculator} />
                Update Scores
              </>
            )}
          </button>
          <button
            type="button"
            className="calculateNotes-btn data-entry-btn"
            onClick={() =>
              populateParentNotesFromChildren(selectedPage, selectedRelease)
            }
            disabled={!isParentPage} // Disable if on a child page
          >
            <FontAwesomeIcon icon={faNoteSticky} />
            Sync Notes
          </button>
          <button
            type="button"
            className="calculateLinks-btn data-entry-btn"
            onClick={() =>
              populateParentLinksFromChildren(selectedPage, selectedRelease)
            }
            disabled={!isParentPage} // Disable if on a child page
          >
            <FontAwesomeIcon icon={faLink} /> Sync Links
          </button>
        </div>
      </form>
    </div>
  );
};

export default DataEntryForm;
