import React from "react";

import PercentChange from "./utils/PercentChange";
import calculatePreviousAverageScores from "./utils/calculatePreviousAverageScores";

const ScoreWithPercentChange = ({
  score,
  label,
  data,
  previousData,
  index,
  isHomePage,
  showScore = true,
  pageId,
  scorePage,
}) => {
  const previousScore =
    previousData && pageId
      ? previousData[pageId]?.datasets[0]?.data[index]
      : [];

  const previousAverageScores = scorePage
    ? calculatePreviousAverageScores(data, previousData, isHomePage)
    : previousScore;

  return (
    <div className={`score-container ${label}`}>
      {showScore && <span className="scoreValue">{score}</span>}
      <PercentChange
        currentValue={score}
        datasetIndex={index}
        previousAverageScores={previousAverageScores}
      />
    </div>
  );
};

export default ScoreWithPercentChange;
