// Navbar.js
import React from "react";
import { Link } from "react-router-dom";
import uselyLogo from "./images/usely-logo.svg";
const Navbar = () => (
  <header className="header">
    <div className="header-top">
      <Link to="/" className="logoContain">
        <img src={uselyLogo} alt="Usely" className="logo" />
      </Link>
      <div className="auth-links">
        <Link to="/auth" className="login-btn">
          Login
        </Link>
        <Link to="/signup" className="signup-btn">
          Sign Up
        </Link>
      </div>
    </div>
  </header>
);

export default Navbar;
